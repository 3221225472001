import React, { useState, useEffect } from 'react';
import { SolutionOutlined } from '@ant-design/icons';
import { RedoOutlined } from '@ant-design/icons';
import { Col, Row, Form, Button, Space, Typography, Radio, Divider, Result, theme, List } from 'antd';
import { ContactInfoData, CRMConnectionData, UsersData } from '../AppState';
//type SizeType = ConfigProviderProps['componentSize'];

const Submit = ({ prev, next }) => {
    const { token } = theme.useToken();
    const [size] = useState('large');
    const [isSubmitting, setSubmitting] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);
    const [bulkUpload, setBulkUpload] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [payloadJSON, setPayloadJSON] = useState('');
    const [submissionStatus, setSubmissionStatus] = useState('none'); // none, success, error

    // Restore data from localStorage on component mount
    useEffect(() => {
        const savedData = localStorage.getItem('formData');
        if (savedData) {
            const parsedData = JSON.parse(savedData);

            // Restore ContactInfoData
            for (const key in parsedData.ContactInfoData) {
                ContactInfoData.value[key].value = parsedData.ContactInfoData[key];
            }

            // Restore CRMConnectionData
            for (const key in parsedData.CRMConnectionData) {
                CRMConnectionData.value[key].value = parsedData.CRMConnectionData[key];
            }

            // Restore UsersData
            UsersData.value = parsedData.AccountsData;

            // Restore bulk upload state
            setBulkUpload(parsedData.BulkUpload);
        }
    }, []);

    useEffect(() => {
        // Build payload dynamically
        let payload = { ContactInfoData: {}, CRMConnectionData: {}, AccountsData: [] };
        for (const key in ContactInfoData.value) {
            payload['ContactInfoData'][key] = ContactInfoData.value[key].value;
        }
        for (const key in CRMConnectionData.value) {
            payload['CRMConnectionData'][key] = CRMConnectionData.value[key].value;
        }
        payload['AccountsData'] = UsersData.value;
        payload['BulkUpload'] = bulkUpload;

        console.log('Final payload:', payload);
        setPayloadJSON(JSON.stringify(payload));
    }, [bulkUpload]);

    const onFinish = async () => {
        setSubmitting(true);

        try {
            console.log('Submitting payload:', payloadJSON);

            const testRun = ContactInfoData.value['teamname'].value.toLowerCase().includes('test')
                ? 'Yes'
                : 'No';
            // POST request to API endpoint
            //TEST- https://webhook.site/FUB-Testing
            //PROD- https://homebot-osirs-crm-sync.azurewebsites.net/api/Setup-1-NewAccount-Received

            if (testRun === 'Yes') {
                const response = await fetch('https://webhook.site/FUB-Testing', {
                    method: 'POST',
                    headers: { "Content-Type": "application/json" },
                    redirect: "follow",
                    body: payloadJSON,
                });

                if (response.ok) {
                    console.log('Test Run Submission to Test URL Succeeded!');
                } else {
                    console.log('Test Run Submission to Test URL FAILED!');
                }

                const prodResponse = await fetch('https://homebot-osirs-crm-sync.azurewebsites.net/api/Setup-1-NewAccount-Received', {
                    method: 'POST',
                    headers: { "Content-Type": "application/json" },
                    redirect: "follow",
                    body: payloadJSON,
                });

                if (prodResponse.ok) {
                    console.log('Test Run Submission to Production URL Succeeded!');
                } else {
                    console.log('Test Run Submission to Production URL FAILED!');
                }

                setSubmitted(true);
                setSubmissionStatus('success');
            } else {
                const liveResponse = await fetch('https://homebot-osirs-crm-sync.azurewebsites.net/api/Setup-1-NewAccount-Received', {
                    method: 'POST',
                    headers: { "Content-Type": "application/json" },
                    redirect: "follow",
                    body: payloadJSON,
                });
                if (liveResponse.ok) {
                    setSubmitted(true);
                    setSubmissionStatus('success');
                    console.log('Submission succeeded!');
                } else {
                    throw new Error('Submission failed!');
                }
            }
        } catch (error) {
            console.error(error);
            setErrorText('Inputted Data Failed to Submit. Please Try Again.');
            setSubmissionStatus('error');
        } finally {
            setSubmitting(false);
        }
    };

    // OLD Hangle Retry handleRetry = () => { setSubmissionStatus('none'); setErrorText(''); setSubmitted(false); };
    const handleRetry = () => {
        // Save data to localStorage and refresh the URL
        localStorage.setItem('formData', payloadJSON);
        window.location.href = 'https://integrations.homebot.ai';
    };

    return (
        <Form layout="vertical" onFinish={onFinish}>
            {/* Show Form Content if Submission is Not Complete */}
            {submissionStatus === 'none' && !isSubmitted && (
                <>
                    <Row gutter={24}>
                        <Col span={16} offset={4}>
                            <Typography.Title
                                level={4}
                                style={{
                                    textAlign: 'center',
                                    marginTop: '5px',
                                    marginBottom: '40px',
                                    color: token.colorLink,
                                }}
                            >
                                Add Contacts & Submit Integration
                            </Typography.Title>
                        </Col>
                    </Row>

                    {/* Import All Contacts Question */}
                    <Row gutter={24}>
                        <Col span={16} offset={4}>
                            <Typography.Text
                                style={{
                                    fontSize: '18px',
                                    fontWeight: 'normal',
                                    marginBottom: '10px',
                                    display: 'block',
                                }}
                            >
                                Import All Contacts w/ Address to Homebot?
                            </Typography.Text>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8} offset={4}>
                            <Radio.Group
                                onChange={(e) => setBulkUpload(e.target.value)}
                                style={{ marginLeft: '8px' }}
                            >
                                <Radio.Button value="Yes">Yes</Radio.Button>
                                <Radio.Button value="No">No</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12} offset={6}>
                            <Divider
                                style={{
                                    backgroundColor: 'white',
                                    height: '1px',
                                    marginTop: '30px',
                                    marginBottom: '5px',
                                    textAlign: 'center',
                                }}
                            />
                        </Col>
                    </Row>



                    {/* Instruction for Bulk Upload */}
                    {bulkUpload === 'No' && (
                        <>
                            {/* Tag to Add to Contacts */}
                            <Row gutter={24}>
                                <Col span={10} offset={7}>
                                    <Typography.Text
                                        style={{
                                            textAlign: 'center',
                                            fontSize: '18px',
                                            fontWeight: 'normal',
                                            marginTop: '5px',
                                            marginBottom: '10px',
                                            display: 'block',
                                        }}
                                    >
                                        Add The Following Tag to Any Contacts within 24 Hours of Submission & They Will Be Imported Upon Activation
                                    </Typography.Text>
                                    <br />

                                    <Typography.Text strong style={{ textAlign: 'center', fontSize: '24px', display: 'block', textDecoration: 'underline', }}>
                                        #Homebot_Import
                                    </Typography.Text>
                                    <Typography.Text
                                        style={{ fontStyle: 'italic', textAlign: 'center', fontSize: 'small', fontWeight: 'regular', marginTop: '0px', marginBottom: '10px', display: 'block' }}
                                    >
                                        Tag to Add to Contacts. Copy Exactly as Shown
                                    </Typography.Text>
                                </Col>
                            </Row>

                            {/* Divider */}
                            <Row gutter={24}>
                                <Col span={4} offset={10}>
                                    <Divider
                                        style={{
                                            dashed: true,
                                            backgroundColor: 'white',
                                            height: '1px',
                                            marginTop: '15px',
                                            marginBottom: '0px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </Col>
                            </Row>

                            {/* Header Text */}
                            <Row gutter={24}>
                                <Col span={16} offset={4}>
                                    <Typography.Title
                                        level={4}
                                        style={{
                                            textAlign: 'center',
                                            marginBottom: '20px',
                                            fontWeight: 'bold',
                                            color: token.colorLink,
                                        }}
                                    >
                                        How to Use the Homebot_Import Tag Tool in Follow up Boss
                                    </Typography.Title>
                                </Col>
                            </Row>

                            {/* Embedded Video */}
                            <Row gutter={24}>
                                <Col span={16} offset={4}>
                                    <div style={{ position: 'relative', paddingBottom: '40%', height: 0, margin: '5px auto', width: '70%', maxWidth: '600px' }}>
                                        <iframe
                                            src="https://www.loom.com/embed/a83b56109f414fcfbb112a78bd33960d?sid=b9d57585-e745-49b4-a979-6c4277023f3b"
                                            title="Homebot Import Tag Tool Tutorial"
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                border: '2px solid #ccc',
                                                borderRadius: '10px',
                                            }}
                                            allowFullScreen
                                            webkitallowfullscreen="true"
                                            mozallowfullscreen="true"
                                        ></iframe>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}


                    {/* Submission Buttons */}
                    <Row gutter={24}>
                        <Col span={16} offset={4}>
                            <Form.Item style={{ textAlign: 'center', marginTop: 1 }}>
                                <Space>
                                    <Typography.Text type="danger">{errorText}</Typography.Text>
                                </Space>
                            </Form.Item>
                            <Form.Item style={{ textAlign: 'center', marginTop: 1 }}>
                                <Space>
                                    <Button type="primary" size="large" htmlType="submit" disabled={isSubmitting}>
                                        Submit
                                    </Button>
                                    <Button size="large" disabled={isSubmitting} onClick={() => prev()}>
                                        Previous
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            )}


            {submissionStatus === 'success' && (
                <>
                    <Result
                        status="success"
                        title={
                            ContactInfoData.value['newOrUpdateIntegration'] === 'Update'
                                ? 'Integration Details Updated Successfully!'
                                : 'Integration Submitted Successfully!'
                        }
                        subTitle={
                            ContactInfoData.value['newOrUpdateIntegration'] === 'Update'
                                ? 'Changes to Your Integration will Take Effect within 24-48 Hours.'
                                : 'Your Integration Will be Activated Within The Next 24-48 Hours.'
                        }
                        extra={[
                            <Button
                                type="primary"
                                icon={<SolutionOutlined />}
                                size={size}
                                key="how-to"
                                onClick={() =>
                                    window.open(
                                        'https://help.homebotapp.com/en/articles/10119918-follow-up-boss-integration',
                                        '_blank'
                                    )
                                }
                                style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Continue Integration Help Center
                            </Button>,
                        ]}
                    />

                    {/* Additional Content Below Buttons */}
                    <Row gutter={24}>
                        <Col span={12} offset={6}>
                            {/* Header with Divider */}
                            <Divider orientation="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                Integration Features & Additional Actions
                            </Divider>

                            {/* Feature List */}
                            <List
                                itemLayout="horizontal"
                                dataSource={[
                                    {
                                        title: '  Automatically Add Buyer & Seller Contacts',
                                        description: 'Add & Sync Buyer/Seller contacts from CRM to Homebot Automatically',
                                    },
                                    {
                                        title: '  Send Digest Reports When You Want',
                                        description: 'Send & View Digest Report Automatically',
                                    },
                                    {
                                        title: ' Receive All Client Alert Events that Occur in Homebot',
                                        description: 'Receive Immediate Client Events in Homebot back in CRM',
                                    },
                                ]}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={
                                                <div style={{
                                                    width: '30px',
                                                    height: '30px',
                                                    borderRadius: '50%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: 'white',
                                                    fontSize: '22px',
                                                    fontWeight: 'bold',
                                                }}>
                                                    ✅
                                                </div>
                                            }
                                            title={
                                                <Typography.Text style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                    {item.title}
                                                </Typography.Text>
                                            }
                                            description={
                                                <Typography.Text style={{ fontSize: '14px', color: '#555', fontStyle: 'italic' }}>
                                                    {item.description}
                                                </Typography.Text>
                                            }
                                        />
                                    </List.Item>
                                )}
                                style={{
                                    marginBottom: '5px',
                                    borderColor: token.colorBorderSecondary,
                                }}
                            />
                            <Row gutter={24}>
                                <Col span={8} offset={8}>
                                    <Divider style={{ borderColor: '#7cb305' }}></Divider>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={18} offset={2}>
                                    {/* Header with Divider */}
                                    <Divider orientation="center" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        Integration Features & Additional Actions
                                    </Divider>

                                    {/* Notice Statement */}
                                    <Typography.Text
                                        style={{
                                            display: 'block',
                                            color: 'red',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            marginBottom: '15px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        **Notice. The items below have been installed automatically into your CRM. DO NOT Delete, or Modify the Added Lead, Tags, Action Plans or Send Digest Email Template. If This Occurs, The Integration WILL NOT Work or Operate as Expected.
                                        Thank You!**
                                    </Typography.Text>
                                </Col>
                            </Row>

                            <List
                                itemLayout="horizontal"
                                dataSource={[
                                    {
                                        title: 'Action Plan w/ Tags for Reference',
                                        description: 'Action Plan Titled- "Homebot Action & Event Result Tags Reference" DO NOT DELETE OR MODIFY',
                                    },
                                    {
                                        title: 'Send Digest Email Template & Action Plan',
                                        description: 'Action Plan Titled- "Homebot Send Digest Email" & Email Template- "Homebot Digest Email" DO NOT DELETE OR MODIFY',
                                    },
                                    {
                                        title: 'A Sample Lead Contact w/ Name- "Homebot Reference"',
                                        description: 'New Lead w/ Name- "Homebot Reference" DO NOT DELETE OR MODIFY',
                                    },
                                ]}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={
                                                <div style={{
                                                    width: '30px',
                                                    height: '30px',
                                                    borderRadius: '50%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: 'white',
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                }}>
                                                    ➡️
                                                </div>
                                            }
                                            title={
                                                <Typography.Text style={{ fontSize: '14px', fontWeight: 'regular' }}>
                                                    {item.title}
                                                </Typography.Text>
                                            }
                                        />
                                    </List.Item>
                                )}
                                style={{
                                    marginBottom: '15px',
                                    borderColor: token.colorBorderSecondary,
                                }}
                            />


                            {/* Video Section */}
                            <Divider orientation="center" style={{ fontSize: '22px', fontWeight: 'bold', color: token.colorLink }}>
                                How Homebot Integration Works
                            </Divider>
                            <div
                                style={{
                                    position: 'relative',
                                    paddingBottom: '40%',
                                    height: 0,
                                    margin: '5px auto',
                                    width: '80%',
                                    maxWidth: '800px',
                                }}
                            >
                                <iframe
                                    src="https://www.youtube.com/embed/2oQekjvd4ss?si=gfQNmXdf4m5TTSrA&rel=0&modestbranding=1&controls=0&autoplay=1&showinfo=0"
                                    title="How Does Follow up Boss to Homebot Integration Work"
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        border: '2px solid #ccc',
                                        borderRadius: '10px',
                                    }}
                                    referrerpolicy="strict-origin-when-cross-origin"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                ></iframe>
                            </div>
                        </Col>
                    </Row>
                </>
            )}


            {/* Error Result */}
            {submissionStatus === 'error' && (
                <Result
                    status="error"
                    title="Submission Failed"
                    subTitle={errorText}
                    extra={[
                        <Button
                            type="danger"
                            icon={<RedoOutlined />}
                            size={size}
                            key="retry"
                            onClick={handleRetry}
                            style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}
                        >
                            Retry
                        </Button>,
                    ]}
                />
            )}
        </Form>
    );
};

export default Submit;
